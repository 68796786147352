import React from "react";
import { Layout, Seo } from "components";

// import page style
import "./how-to-apply-patch.scss";
import SideNav from '../../codes/components/side-nav';


const HowToApplyPatch = () => {
  return (
    <Layout indication="patient" className="how-to-apply-patch">
      <Seo pageTitle="How to Apply Butrans® (buprenorphine) Transdermal System CIII"
      pageDescription="Use these instructions to apply Butrans Transdermal System. See Full Prescribing Info, Med Guide & Boxed Warning." />
      <div className="body-content-container">
      <SideNav pageId="pat-resources" />
        <div className="body-content gutter-all">
          <h1>Patch Application & Rotation Tutorial</h1>
          <iframe
            src="/tools/patch-tracker/index.html"
            title="Patch Application"
            style={{ width: "582px", height: "362px" }}
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};

export default HowToApplyPatch;
